import React from "react"
import { Email } from "react-obfuscate-email"
// import { useStaticQuery, graphql } from "gatsby"
import { navMenuItems } from "components/Data/MenuItems"
import { contactDetails } from "components/Data/ContactDetails"
import styled from "styled-components"
import device from "utils/media"
import FooterCopyright from "components/Layout/FooterCopyright"
import FooterMenu from "components/Layout/FooterMenu"
import FooterContactCta from "components/Layout/FooterContactCta"

import FacebookIcon from "../../../content/assets/icon-facebook-white.png"
import LinkedInIcon from "../../../content/assets/icon-linkedin-white.png"
import AddressIcon from "../../../content/assets/icon-address.svg"
import EmailIcon from "../../../content/assets/icon-email.svg"
import WebIcon from "../../../content/assets/icon-web.svg"
import { ReactComponent as Logo } from "../../../content/assets/winr-data-logo.svg"

const FooterLogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media ${device.lg} {
    align-items: flex-start;
    margin-bottom: 0;
  }
  svg {
    height: 48px;
    path {
      fill: #ffffff;
      &.winr-data-logo_svg__b {
        fill: ${props => props.theme.color.dark_purple};
      }
    }
  }
`

const FooterContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.black};
  margin: 0 auto;
  color: white;
  z-index: 2;
  padding: 0;
  font-size: ${props => props.theme.text.xs};
`

const FooterTop = styled.div`
  background-color: ${props => props.theme.color.dark_purple};
  padding: ${props => (props.noFooterCta ? "36px 0 30px 0" : "0 0 30px 0")};

  p {
    margin-bottom: 18px;
  }
  a {
    color: white;
    &:hover {
      opacity: 0.7;
    }
    i:hover {
      opacity: 0.7;
    }
  }

  .footer-main {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer_social {
    padding-top: 14px;
    text-align: center;
    justify-content: center;
    @media ${device.lg} {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
`

const FooterMenusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;

  @media ${device.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  @media ${device.lg} {
    text-align: left;
    padding-bottom: 0;
    padding-left: 40px;
  }
`

const Address = styled.p`
  display: flex;
  color: #ffffff;

  i {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: block;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    &.address {
      background-image: url(${AddressIcon});
    }
  }

  @media screen and (max-width: 419px) {
    text-align: center;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 15px;
    justify-content: center;

    i {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 420px) {
    justify-content: center;
    flex-direction: row;
    align-items: center;

    i {
      margin-bottom: 0;
    }
  }

  @media ${device.lg} {
    justify-content: flex-start;
  }
`

const ContactLinks = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  color: #ffffff;

  @media screen and (max-width: 419px) {
    flex-direction: column;
  }

  @media screen and (min-width: 420px) {
    justify-content: center;
  }

  @media ${device.lg} {
    justify-content: flex-start;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }
    a {
      color: white;
      &:hover {
        opacity: 0.7;
      }
      i:hover {
        opacity: 0.7;
      }
    }

    @media screen and (max-width: 419px) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 15px;
    }

    i {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      display: block;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;

      @media screen and (max-width: 419px) {
        margin-right: 0;
        margin-bottom: 5px;
      }
      &.email {
        background-image: url(${EmailIcon});
      }
      &.web {
        background-image: url(${WebIcon});
      }
    }
  }
`

const SocialLinks = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: center;

  @media ${device.lg} {
    justify-content: flex-start;
  }

  li {
    margin-bottom: 0;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }

    i {
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      &.Facebook {
        background-image: url(${FacebookIcon});
      }
      &.LinkedIn {
        background-image: url(${LinkedInIcon});
      }
    }
  }
`

const Footer = ({ pageName, footerCtaSettings }) => {
  const footerMenus = navMenuItems

  return (
    <FooterContainer pageName={pageName}>
      <FooterTop noFooterCta={pageName === `contact` || pageName === `optout`}>
        {pageName !== `contact` && pageName !== `optout` && (
          <FooterContactCta
            showFooterCtas={false}
            footerCtaSettings={footerCtaSettings}
          />
        )}

        <div className="footer-main container x-large">
          <div className="row align-items-center-lg">
            <div className="col lg-2 xxlg-3">
              <FooterLogoWrap>
                <Logo />
              </FooterLogoWrap>
            </div>
            <div className="col lg-6">
              <FooterMenusWrapper>
              </FooterMenusWrapper>
            </div>

            <div className="col lg-4 xxlg-3">
              <Address>
                <i aria-hidden="true" className="address"></i>{" "}
                {contactDetails.address}
              </Address>
              <ContactLinks>
                <li>
                  <i aria-hidden="true" className="email"></i>
                  <Email
                    email={contactDetails.email}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="📧 Email us"
                  />
                </li>
              </ContactLinks>
              <div className="footer_social">
                <SocialLinks>
                  {contactDetails.social_links.map((node, index) => (
                    <li key={index}>
                      <a
                        href={node.link}
                        target="_blank"
                        title={node.icon}
                        rel="noopener noreferrer"
                      >
                        <i aria-hidden="true" className={node.icon}></i>
                      </a>
                    </li>
                  ))}
                </SocialLinks>
              </div>
            </div>
          </div>
        </div>
      </FooterTop>

      <FooterCopyright />
    </FooterContainer>
  )
}

export default Footer
