import React from "react"
import styled, { css } from "styled-components"
import device from "utils/media"

const baseStyle = css`
  color: black;
  font-family: ${props => props.theme.font.feature};
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  line-height: 1;
`

const HeadingOne = styled.h1`
  font-size: 40px;
  margin-bottom: 25px;
  ${baseStyle};

  @media ${device.sm} {
    font-size: 60px;
  }
`

const HeadingTwo = styled.h2`
  font-size: 36px;
  font-size: ${props => props.theme.text.xl};
  margin-bottom: 20px;
  ${baseStyle};
`

const HeadingThree = styled.h3`
  font-size: ${props => props.theme.text.md_2};
  margin-bottom: 15px;
  ${baseStyle};

  @media ${device.md} {
    font-size: ${props => props.theme.text.lg};
  }

  @media ${device.lg} {
    font-size: ${props => props.theme.text.xxl};
  }
`

const HeadingFour = styled.h4`
  font-size: ${props => props.theme.text.md};
  font-weight: bold;
  margin-bottom: 10px;
  ${baseStyle};
`

const FeatureTitle = ({ h2, h3, h4, ...props }) => {
  if (h2) return <HeadingTwo {...props} className={props.className} />
  if (h3) return <HeadingThree {...props} className={props.className} />
  if (h4) return <HeadingFour {...props} className={props.className} />
  return <HeadingOne {...props} className={props.className} />
}

export default FeatureTitle
