import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import defaultOpenGraphImage from "../../content/assets/winr-data-og-logo.jpg"

const SeoComponent = ({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  type,
  noIndex,
}) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const ogImageUrl = `${data.site.siteMetadata.siteUrl}${
          image || defaultOpenGraphImage
        }`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <meta
              name="robots"
              content={noIndex ? "noindex, nofollow" : "index, follow"}
            />
            <meta name="description" content={metaDescription} />
            {title && <meta property="og:title" content={title} />}
            {metaDescription && (
              <meta property="og:description" content={metaDescription} />
            )}
            <meta property="og:image" content={ogImageUrl} />
            <meta property="og:type" content={type || "website"} />
            <meta name="twitter:card" content="summary" />
            {
              <meta
                name="twitter:creator"
                content={data.site.siteMetadata.author}
              />
            }
            {title && <meta name="twitter:title" content={title} />}
            {metaDescription && (
              <meta name="twitter:description" content={metaDescription} />
            )}
            <meta name="apple-mobile-web-app-capable" content="yes" />
          </Helmet>
        )
      }}
    />
  )
}

SeoComponent.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SeoComponent.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SeoComponent

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
