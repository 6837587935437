import React from "react"
import styled from "styled-components"
import Link from "components/Link"
import device from "utils/media"

const ContactCtaWrapper = styled.div`
  color: black;
  background-color: ${props => props.bgColour};
  margin-bottom: 34px;
  padding: 30px 0;

  @media ${device.md} {
    .row {
      align-items: center;
    }
  }

  .interested-cta-text {
    display: flex;
    align-items: flex-start;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 14px;

    @media ${device.md} {
      align-items: flex-start;
      text-align: left;
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 6px;
      letter-spacing: -0.01em;
      line-height: 1.2;
      @media ${device.md} {
        margin-top: 0;
        margin-bottom: 0;
      }

      span {
        font-weight: 200;
      }
    }
    p {
      margin-top: 6px;
      margin-bottom: 0;
      font-size: 2.4rem;
      @media ${device.md} {
        font-size: 3rem;
      }
      img {
        margin: -20px 4px 0 0;
      }
    }
  }

  ul.interested-cta-buttons {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: 420px) {
      flex-direction: row;
      align-items: center;
    }

    @media ${device.md} {
      justify-content: flex-end;
      height: 100%;
      text-align: left;
    }

    li {
      @media screen and (max-width: 419px) {
        display: block;
        width: 100%;
        text-align: center;
      }

      @media screen and (min-width: 420px) {
        display: flex;
        align-items: center;
      }

      .btn {
        padding: 18px 25px;
        font-style: italic;
        &-contact {
          background-color: ${props => props.theme.color.dark_purple};
        }
        &-learn_more {
          color: ${props => props.theme.color.dark_purple};
        }
      }
    }
  }
`

const FooterContactCta = ({ showFooterCtas, footerCtaSettings }) => {
  return (
    <ContactCtaWrapper
      morePadding={showFooterCtas}
      bgColour={
        footerCtaSettings && footerCtaSettings.cta_background_colour
          ? footerCtaSettings.cta_background_colour
          : "#f5f5f5"
      }
    >
      <div className="container x-large">
        <div className="row">
          <div className="col md-8 lg-9">
            <div className="interested-cta-text">
              <h2
                className="text-uppercase"
                dangerouslySetInnerHTML={{
                  __html:
                    footerCtaSettings && footerCtaSettings.cta_text
                      ? footerCtaSettings.cta_text
                      : "Contact us to&nbsp;get&nbsp;started",
                }}
              />
            </div>
          </div>
          <div className="col md-4 lg-3">
            <ul className="nav interested-cta-buttons">
              <li>
                <Link
                  className="btn btn-contact text-uppercase"
                  to={`/contact/`}
                >
                  {footerCtaSettings && footerCtaSettings.cta_button_text
                    ? footerCtaSettings.cta_button_text
                    : "Get Started"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ContactCtaWrapper>
  )
}

export default FooterContactCta
