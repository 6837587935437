import { css } from "styled-components"
import device from "utils/media"

export const underline = css`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    display: inline-block;
    background-color: inherit;
    transition: ${props => props.theme.transition.default};
    background-color: ${props => props.theme.color.offBlack};
    will-change: transform;
  }

  &:hover {
    &::after {
      background-color: ${props => props.theme.color.purple};
      transform: translateY(-2px);
    }
  }
`

export const teaseTitle = css`
  font-size: ${props => props.theme.text.base};

  @media ${device.md} {
    font-size: ${props => props.theme.text.base_2};
  }

  @media ${device.xl} {
    font-size: ${props => props.theme.text.md};
  }
`
