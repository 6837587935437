const contactDetails = {
  email: "info@winrdata.com",
  address: "100 Walker Street, North Sydney, 2060",
  social_links: [
    {
      link: "https://www.linkedin.com/company/winr-media/",
      icon: "LinkedIn",
    },
  ],
}

export { contactDetails }
