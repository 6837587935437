import { createGlobalStyle } from "styled-components"
// import device from "utils/media"
import DotPattern from '../../content/assets/dot.png'
import DotPatternWhite from '../../content/assets/dot_white.png'

const GlobalStyles = createGlobalStyle`

  body {
    font-family: 'Montserrat';
    position: relative;
  }

  /* headings */
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    line-height: 0.9;
  }

  h1, h2 {
    font-weight: 900;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  header a {
    color: black;
  }

  blockquote {
    font-weight: 700;
    margin: ${props => props.theme.spacing.lg} 0;
  }

  .underline {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      display: inline-block;
      background-color: white;
    }
  }

  .pattern {
    position: absolute;
    background-image: url(${DotPattern});
    background-repeat: repeat;
    background-size: 7.5px;
    z-index: 1;

    &__white {
      background-image: url(${DotPatternWhite});
    }

    &__large {
      background-size: 15px;
    }

    &__medium {
      background-size: 10px;
    }

    &.light {
      opacity: 0.2;
    }
  }  

  .winr-redact, .winr-redact * {
    word-break: break-all;
  }
`

export default GlobalStyles
