import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Footer from "components/Layout/Footer"

import "@fontsource/rubik/400-italic.css"
import "@fontsource/rubik/700.css"
import "@fontsource/rubik/900.css"
import "@fontsource/rubik/900-italic.css"
import "@fontsource/montserrat"
import "@fontsource/montserrat/400-italic.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/700-italic.css"
import "@fontsource/montserrat/900-italic.css"
import GlobalStyles from "utils/GlobalStyles"
import "styles/main.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Main = styled.div`
  position: relative;
  z-index: 199;
  background-color: #ffffff;
`

const MainInner = styled.div`
  position: relative;
  z-index: 10;
  transition: margin 0.3s ease;
  will-change: margin;
`

const Layout = ({ children, pageName, footerCtaSettings = null }) => {

  const siteMeta = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet title={siteMeta.site.siteMetadata.title} maximum-scale="1.0" user-scalable="no">
        <html lang="en" />
      </Helmet>      
      <GlobalStyles />
      <Main data-layout-parent="">
        <MainInner>
          {children}

          <Footer siteMeta={siteMeta.site.siteMetadata} pageName={pageName} footerCtaSettings={footerCtaSettings} />
        </MainInner>
      </Main>
    </>
  )
}

Layout.propTypes = {
  // siteMeta: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
}

export default Layout
