import React from "react"
import styled from "styled-components"
import he from "he"
import Link from "components/Link"
import device from "utils/media"

const FooterMenuWrapper = styled.div`
  color: #ffffff;
  padding: 15px 0px;
  @media ${device.sm} {
    flex-basis: 100%;
  }
  @media ${device.md} {
    flex-basis: 100%;
    padding-right: 10px;
  }    
  @media ${device.lg} {
    padding: 0;
    flex-basis: 50%;
  }
  @media screen and (min-width: 1300px) {
    // flex-basis: 25%;
    padding-bottom: 0;
    padding-right: 20px;
  }
  
  h5 {
    margin-bottom: 18px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: ${props => props.theme.font.feature};
    font-weight: 900;
    font-size: ${props => props.theme.text.sm};
  }

  ul li {
    margin: 6px 0;
    font-size: ${props => props.theme.text.xs};      
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: #b6b6b6;
    }      
  }
`

const FooterMenu = ({ title, items }) => {
  return (
    <FooterMenuWrapper>
      <h5>{title}</h5>
      <ul>
        {items && items.map((item, idx) => (
          <li key={`menu-item-${idx}`}>
            <Link 
              to={item.path}            
            >
              {he.decode(item.title)}
            </Link>
          </li>
        ))}
      </ul>      
    </FooterMenuWrapper>
  )
}

export default FooterMenu