import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import device from "utils/media"

const FooterBottom = styled.div`
  background-color: black;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 10px 0;
  transition: all 0.3s ease;
  will-change: margin;
  transition: bottom 0.3s ease;
  text-align: center;

  @media ${device.md} {
    text-align: left;
  }

  @media ${device.xxl} {
    flex-direction: row;
    margin: 30px 0;
  }

  .left {
    color: #acacac;
    p {
      margin: 24px 0;
      a {
        color: #ccc;
        &:hover {
          color: #ffffff;
        }
      }

      @media ${device.xl} {
        max-width: 80%;
      }

      @media ${device.xxl} {
        margin: 0;
      }
    }
  }
`

const FooterCopyright = () => {
  return (
    <FooterBottom>
      <div className="container x-large">
        <div className="left">
          <p>
            Copyright &copy; {new Date().getFullYear()} WINR Data Pty Ltd. All
            Rights Reserved. <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>{" "}
            <Link to="https://winrdata.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy&nbsp;Policy</Link>{" "}
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <Link to="/cookie-policy/">Cookie&nbsp;Policy</Link>{" "}
          </p>
        </div>
      </div>
    </FooterBottom>
  )
}

export default FooterCopyright
